.contact {
    background-color: $color-main;
    padding-bottom: 20px;


    .title {
        color: $color-main2;
        margin-bottom: 25px;
        margin-top: 40px;
        text-align: center;

        @include lg {
            text-align: unset;
        }
    }

    p {
        color: $color-light;

    }

    .title ~ p {
        font-family: $font-second;
        margin-bottom: 45px;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;

        @include lg {
            text-align: unset;
        }
    }

    .xs {
        font-size: 12px;
        opacity: 0.5;
        margin-bottom: 0;
        font-family: $font-second;

        @include lg {
            font-size: 14px;
        }
    }

    .contact-container {
        display: flex;
        flex-wrap: wrap;

        .contact-wrapper {
            width: 100%;

            @include lg {
                width: 50%;
            }
        }
    }

    .contact-item {
        display: flex;
        justify-content: center;

        @include lg {
            justify-content: unset;
        }

        p {
            font-family: $font-second;
            margin: 0 0 10px;
        }

        .contact-item-icon {
            height: 25px;
            width: auto;
        }

        .contact-item-line {
            margin: 7px 10px auto;
            width: 18px;
            height: 1px;
            background-color: $color-light;

            @include lg {
                width: 30px;
            }
        }

        .contact-item-text {
            color: $color-light;

            .bank-number {
                color: #ffffff !important;
            }

            .contact-margin {
                margin-right: 30px;
            }

            span {
                display: block;

                @include lg {
                    display: inline;
                }
            }
        }
    }

    .contact-dot {
        width: 100%;
        margin: 0px 0 12px;

        .dot {
            width: 7px;
            height: 7px;
            display: inline-block;
            margin: auto;
            background-color: $color-main2;
            margin-left: 49%;
            border-radius: 100%;

            @include lg {
                margin-left: 25%;
            }
        }
    }

    .contact-wrapper2 {
        margin: 60px 0;
    }

    .contact-block {
        width: 100%;
        background-color: $color-main;
        box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.4);
        padding: 30px 15px;
        display: flex;
        transition: $transition;
        justify-content: center;

        @include lg {
            padding: 30px 45px;
            justify-content: unset;
        }

        &:hover {
            transform: scale(1.05);
        }

        &:first-of-type {
            margin-bottom: 25px;
        }

        .contact-block-icon {
            height: 40px;
            margin-right: 15px;
            margin-top: 7px;

            @include md {
                height: 60px;
                margin-right: 30px;
            }

            @include lg {
                margin-top: unset;
            }
        }

        .contact-block-text {

            .contact-block-text2 {
                font-size: 18px;
                color: $color-main2;
                font-weight: 700;
                font-family: $font-second;
                margin: 0;

                @include md {
                    font-size: 35px;
                }

                @include lg {
                    font-size: 28px;
                }
            }

            .contact-block-text2-small {
                font-size: 12px;
                margin-top: 5px;

                @include md {
                    font-size: 22px;
                }

                @include lg {
                    font-size: 18px;
                }
            }
        }
    }
}

@media (max-width:992px) {
    .contact-map {
        order: 2;
        max-height: 400px;
        margin-top: 20px;
    }
}

.contact-form {



    input,
    textarea {
        width: 100%;
        margin: 5px;
        border: 1px solid #b19b66;
        color: #b19b66;
        padding: 18px 20px;
    }

    .privacy {
        display: flex;
        align-items: center;

        input {
            width: 40px;
            margin-left: -6px;
        }
    }

    .padding-title {
        text-align: center;
    }


}

.contact-map-bot {
    margin-bottom: -55px;
    @media (max-width:992px) {
        margin-top: 260px;
        margin-bottom: 52px;
    }
}

/* privacy */

.padding-lr {
    padding: 0 30px;
}

.privacy-policy .privacy-policy-box .header1 {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 37px;
    color: #162768;
    margin: 50px 0;
}

.privacy-policy .privacy-policy-box .header2 {
    font-size: 18px;
    color: #162768;
    font-family: Aller, sans-serif;
    letter-spacing: 1.7px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 30px 0;
}

.privacy-policy .privacy-policy-box .header3 {
    font-size: 12px;
    color: #b19b66;
    letter-spacing: 2px;
    font-weight: 700;
    text-transform: uppercase;
}

.privacy-policy .privacy-policy-box .privacy-policy-list li {
    list-style-type: none;
    line-height: 2.3;
}

.privacy-policy .privacy-policy-box .privacy-policy-list li:before {
    content: "\2022";
    color: #b19b66;
    font-weight: 700;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.privacy-policy .privacy-policy-box .span-gold {
    color: #b19b66;
}

@media (min-width: 992px) {
    .padding-lr {
        padding: 0 200px;
    }
}

.contact-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    margin: 0 auto;

    .left-sec {
        padding-top: 0;
    }
}

.contact-left-title{
    margin-top: 50px;
}

@media (min-width: 992px) {

    .header-small {
        .left-sec {
            width: calc(40% + 30px);
        }

        .header-kontakt {
            width: calc(60% - 30px);
        }
    }
    
    .contact-left-title{
    margin-top: 0;
}
}


