.ml-15{
    margin-left: 15px;
}

.mb-10{
    margin-bottom: 100px;
}

.footer {
    background-color: $color-light;
    padding-top: 30px;
    padding-bottom: 20px;

    .container {
        display: flex;
        flex-direction: column;

        @include lg {
            flex-direction: row;
        }

        .footer-menu {

            ul {
                list-style-type: none;
                padding: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;

                @include lg {
                    flex-direction: row;
                }

                .footer-menu-item {

                    @include lg {
                        margin-right: 15px;
                    }

                    @media (min-width:1480px) {
                        margin-right: 34px; 
                    }


                    a {
                        color: $color-main;
                        font-family: $font-second;
                        font-weight: 600;
                        transition: $transition;
                        text-transform: uppercase;
                        font-size: 11px;
                        letter-spacing: 1.5px;
                    }

                    &:hover a {
                        text-decoration: underline;
                        color: $color-main;
                    }
                }
            }
        }

        .footer-copy {
            font-size: 11px;
            color: $color-main;
            opacity: 0.5;
            text-transform: uppercase;
            font-family: $font-second;
            margin: 30px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            

            @include lg {
                margin: 0 0 0 auto;
                justify-content: unset;
                width: 25%;
            }
            
             @media (min-width:1400px) {
                width: auto;
            }

        }

        .footer-socials {
            display: flex;
            align-items: center;
            justify-content: center;

            @include lg {
                justify-content: unset;
            }

            img {
                height: 40px;
                width: auto;
            }

            a.footer-socials-item {
                display: flex;
                text-decoration: none;
                transition: $transition;

                &:hover {
                    transform: scale(1.1);
                }

                &:nth-of-type(1) {
                    margin-left: 0;

                    @include lg {
                        margin-left: 0px;
                    }
                    
                    @include xl {
                        margin-left: 30px;
                    }
                }

                &:nth-of-type(2) {
                    margin-left: 15px;
                }
            }
        }


    }
}