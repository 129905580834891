.specialization {
    margin-top: 30px;

    .title {
        margin-bottom: 25px;
        text-align: left;

        @include xl {
            text-align: left;
        }
    }

    .title-p {
        margin-bottom: 36px;
        text-align: left;

        @include xl {
            text-align: left;
        }

        &:not(:first-child) {
            margin-bottom: inherit;
        }
    }

    .specialization-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include lg {
            flex-direction: row;
        }

        .specialization-wrapper {
            width: 100%;

            @include xl {
                width: 36.5%;
                margin-right: 3px;
            }

            &:nth-of-type(1) {
                order: 1;

                @include xl {
                    order: 0;
                }
            }

            &:nth-of-type(2) {
                order: 3;

                @include xl {
                    order: 0;
                }
            }

            &:nth-of-type(3) {
                order: 4;

                @include xl {
                    order: 0;
                }
            }

            &:nth-of-type(4) {
                order: 2;

                @include xl {
                    order: 0;
                }
            }

        }

        .specialization-wrapper-big {
            width: 100%;

            @include xl {
                width: 55%;
                margin-right: 5%;
            }

            .title2 {
                margin-top: 30px;
            }
        }

        .specialization-wrapper-p-left {
            padding-left: 12%;
        }

        .specialization-space {

            margin-top: 50px;
            text-align: center;

            @include xl {
                margin-top: unset;
                padding-left: 30px;
                text-align: left;
            }
        }
    }

    .specialization-items {
        display: flex;
        flex-wrap: wrap;



        .specialization-item {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 40px 14px;
            box-shadow: 10px 10px 42px 0px rgba(0, 0, 0, 0.11);
            border: 1px solid #e2e2e2;
            text-align: center;
            position: relative;
            transition: $transition;
            background-color: $color-light;

            @include md {
                width: 33.33%;
            }

            .specialization-icon {
                height: 55px;
                width: auto;
                margin-bottom: 30px;
            }

            .title3 {
                height: 40px;
            }

            @media (min-width:992px) {
                .title3 {
                    font-size: 14px;
                }
            }

            &::before {
                content: "";
                right: 15px;
                bottom: 15px;
                position: absolute;
                @include arrow;
            }

            &:hover {
                transform: scale(1.1);
                z-index: 9;
            }
        }

        @media (max-width:575px) {
            .specialization-item {
                padding: 32px 5px 0px 5px
            }
        }
    }

    .specialization-line {
        @include line;
    }

    @media (max-width:1200px) {
        .sp-line {
            transform: rotate(180deg)
        }
    }

    @media (min-width:1201px) {
        .sp-line {
            display: none;
        }
    }

    .title2 {
        margin-bottom: 20px;
        margin-top: 0;
    }

    .specialization-list {
        list-style-type: none;
        padding-left: 0;
        line-height: 2.3;
        margin-bottom: 20px;
        margin-left: 0;

        ::before {
            content: "\2022";
            color: $color-main2;
            font-weight: bold;
            display: inline-block;
            width: 1.5em;
            margin-left: -1em;
        }
    }

    @media (min-width:1200px) {
        .specialization-list {
            list-style-type: none;
            padding-left: 1em;
            line-height: 2.3;
            margin-bottom: 20px;
            margin-left: 12px;
        }
    }

    .specialization-cta {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        //        text-align: center;
        margin-top: 30px;
        padding-left: 0px;

        @include xl {
            text-align: left;
            margin-top: unset;
            padding-left: 130px;
        }

        .cta {
            margin-bottom: 35px;
        }

        .button {
            width: max-content;
            margin: auto;

            @include xl {
                margin: unset;
            }
        }
    }
}



.specialization-page {
    .specialization-items {
        flex-direction: column;

        @media (min-width:796px) {
            .box-spec {
                display: inline-grid;
                grid-template-columns: 1fr 2fr;
                margin-bottom: 20px;
            }

            .specialization-item {
                width: 97%;
            }
        }

        .sp-box {
            display: flex;
            padding: 20px;
            flex-direction: column;
            width: 100%;

            h2,
            h3,
            h4 {
                color: #162768;
            }

            ul {
                li {
                    list-style-type: none;
                    list-style-position: outside;
                    text-indent: -8px;

                }

                ::before {
                    content: "\2022";
                    color: $color-main2;
                    font-weight: bold;
                    display: inline-block;
                    width: 1.5em;
                    margin-left: -1em;
                }
            }
        }

        @media (min-width:796px) {
            .box-spec-2 {
                display: flex;
                margin-bottom: 20px;

                .specialization-item {
                    width: 24%;
                }
            }
        }




    }
}

.w-100 {
    width: 100% !important;
}

/***************/

@media (min-width:992px) {
    .subpage-side {

        p,
        h1,
        h2,
        h3,
        li {
            padding-left: 130px;
        }

        .sp-box {
            padding: 0 !important;
        }
    }
}

.specialization {
    .button {
        width: 100px;
        text-align: center;
        padding: 10px 16px;
        margin-top: 20px;
        margin-bottom: 40px;
    }
}
@media (min-width:920px) {
.specialization {
    .button {
        margin-bottom: 10px;
    }
}
}

.sl-plus{
        margin-left: 14px !important;
}
