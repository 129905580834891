// colors
$color-light: #fff;
$color-text: #00011f;
$color-bg: #f0f0f0;
$color-main: #162768;
$color-main2: #b19b66;
$color-main3: #a38c56;


// fonts 
@font-face {
    font-family: "Aller";
    src: url("../fonts/Aller.ttf") format("ttf")
}

@font-face {
    font-family: "AllerLight";
    src: url("../fonts/Aller-light.ttf") format("ttf")
}

$font-main: 'Aller', sans-serif;
$font-main-light: "AllerLight", sans-serif;
$font-second: 'Roboto', sans-serif;
$font-third: 'Open Sans', sans-serif;

//animate 
$transition: all 0.5s;



