.blog {
    padding-top: 80px;
    padding-bottom: 70px;
    background-color: $color-bg;
    position: relative;
    margin-bottom: -130px;

    @include lg {
        padding-bottom: 80px;
        margin-top: -100px;
    }

    &::before {
        content: "BLOG";
        font-size: 300px;
        color: #a0a0a0;
        font-weight: 900;
        font-family: $font-third;
        opacity: 0.34;
        position: absolute;
        left: 45%;
        top: 6%;
    }

    .blog-title {
        text-align: center;
        z-index: 9;
        position: relative;

        @include lg {
            text-align: left;
        }

        .title-txt {
            font-family: $font-main-light;
            font-style: italic;
            margin-bottom: 40px;
            color: $color-main;
        }
    }

    .blog-item {
        background-color: $color-light;
        padding: 30px 25px;
        width: 100%;
        display: inline-block;

        @include md {
            width: calc(50% - 15px);
        }

        @include lg {
            width: calc(50% - 20px);
        }

        &:last-of-type {
            margin-left: 0;
            margin-top: 30px;

            @include md {
                margin-left: 20px;
                margin-top: 0;
            }

            @include lg {
                margin-left: 35px;
            }
        }

        .blog-slider-img {
            height: 180px;
            width: 100%;
            position: relative;
            background-image: '';
            background-position: center;
            background-size: cover;

            img {
                object-fit: cover;
                display: flex;
                width: 100%;
                height: 190px;
                z-index: 111;
            }

            .blog-date {
                position: absolute;
                right: 15px;
                bottom: -20px;
                padding: 20px 15px 15px;
                background-color: $color-main2;

                .blog-date-top {
                    font-size: 30px;
                    color: $color-light;
                    font-family: $font-second;
                    font-weight: bold;
                    margin: 0;
                    line-height: 1;
                }

                .blog-date-bottom {
                    font-size: 12px;
                    color: $color-light;
                    margin: 0;
                    font-family: $font-second;
                    font-weight: bold;
                }
            }
        }

        .title3 {
            margin-top: 28px;
            margin-bottom: 18px;
            letter-spacing: normal;
            font-size: 14px;
        }

        .blog-txt {
            margin: 0 0 25px;
        }

        a.blog-more {
            text-transform: uppercase;
            color: $color-main2;
            font-size: 12px;
            font-family: $font-main;
            font-weight: bold;
            letter-spacing: 2px;
            position: relative;
            transition: $transition;

            &::after {
                content: "";
                position: absolute;
                @include arrow;
                bottom: 0;
                right: -10px;
            }

            &:hover {
                letter-spacing: 3px;
            }
        }
    }

    #blog-slider {
        position: relative;
    }

    .blog-slider-arrows {
        position: absolute;
        top: -30px;
        right: 50%;
        transform: translateX(50%);

        @include md {
            transform: translateX(50%);
            top: -55px;
            right: 40px;
        }
    }

    .carousel-control-next,
    .carousel-control-prev {
        position: static;
        display: inline-block;
        width: auto;
    }

    .carousel-control-prev {
        margin-right: 10px;
    }
}
