// Generic Sass mixins

// reset padding and margin
@mixin reset {
  padding: 0;
  margin: 0;
}

@mixin line {
  height: 50px;
  position: relative;
  width: 100%;
  margin-top: 20px;

  .arrow-bg {
    position: absolute;
    background-color: $color-light;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    left: calc(50% - 25px);

    @include xl {
      left: -20px;
    }
  }

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7px 0 7px;
    border-color: $color-main2 transparent transparent transparent;
    line-height: 0px;
    _border-color: $color-main2 #000000 #000000 #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
  }

  .line {
    height: 1px;
    background-color: #dddddd;
    width: calc(100% + 500px);
    position: absolute;
    left: -80px;
    top: 50%;
  }
}

@mixin arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 5px 5px;
  border-color: transparent transparent #0e6838 transparent;
  line-height: 0px;
  _border-color: #000000 #000000 #0e6838 #000000;
  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
}