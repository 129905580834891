@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}


@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: 576px) {
    @content;
  }
}


//container
.p-left {
  padding-left: 15px;

  @include md {
    padding-left: 30px;
  }

  @include lg {
    padding-left: 40px;
  }
}

.p-right {
  padding-right: 15px;

  @include md {
    padding-right: 30px;
  }

  @include lg {
    padding-right: 40px;
  }
}

.p-left2 {
  padding-left: 30px;

  @include md {
    padding-left: 25px;
  }

  @include lg {
    padding-left: 80px;
  }
}

.p-right2 {
  padding-right: 30px;

  @include md {
    padding-right: 25px;
  }

  @include lg {
    padding-right: 80px;
  }
}

.p-left-inner {
  padding-left: 0;

  @include xl {
    padding-left: 130px;
  }
}

.p-right-inner {
  padding-right: 0;

  @include xl {
    padding-right: 130px;
  }
}