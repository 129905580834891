.ebook {
    .ebook-bg {
        background-image: url(../images/header-mask.jpg);
        background-size: cover;
        background-position: left center;
        width: 100%;
        height: 100%;
        background-color: $color-main;
        display: flex;
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        filter: contrast(2);
        flex-direction: row;
        align-items: unset;
        text-align: left;
        justify-content: space-between;
        z-index: 9;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-right: 0;
        }

        .ebook-mask {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $color-main;
            opacity: 0.7;
            left: 0;
            top: 0;
        }

        .ebook-icon {
            margin-right: 25px;
            z-index: 3;
            text-align: center;

            @media (max-width: 768px) {
                margin-right: 0;
                margin-bottom: 30px;
            }

            img {
                height: 85px;
                width: auto;
            }
        }

        .ebook-title {
            z-index: 3;
            width: 78%;
            padding-right: 0;

            @include md {
                padding-right: 30px;
            }

            .title2,
            p {
                color: $color-light;
            }

            .title2 {
                margin-bottom: 7px;
            }

            .italic {
                font-style: italic;
                margin: 0;
                font-weight: 100;
                font-family: $font-main-light;
            }

        }

        a.ebook-download {
            margin: 0;
            z-index: 3;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            width: 85px;
            height: 85px;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-main2;
            margin-top: 30px;

            @include md {
                margin-top: 0;
            }

            img {
                height: 40px;
            }

            p {
                margin: 7px 0 0;
                color: $color-main2;
                font-size: 12px;
                font-family: $font-third;
            }
        }
    }
}

.med-info{
    .ebook-title{
        margin-left: 40px;
    }
}

/* Privacy */
.padding-lr {
			padding: 0 30px;
		}

		.privacy-policy .privacy-policy-box .header1 {
			font-size: 30px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 4px;
			line-height: 37px;
			color: #162768;
			margin: 50px 0;
		}

		.privacy-policy .privacy-policy-box .header2 {
			font-size: 18px;
			color: #162768;
			font-family: Aller, sans-serif;
			letter-spacing: 1.7px;
			font-weight: 700;
			text-transform: uppercase;
			margin: 30px 0;
		}

		.privacy-policy .privacy-policy-box .header3 {
			font-size: 12px;
			color: #b19b66;
			letter-spacing: 2px;
			font-weight: 700;
			text-transform: uppercase;
		}

		.privacy-policy .privacy-policy-box .privacy-policy-list li {
			list-style-type: none;
			line-height: 2.3;
		}

		.privacy-policy .privacy-policy-box .privacy-policy-list li:before {
			content: "\2022";
			color: #b19b66;
			font-weight: 700;
			display: inline-block;
			width: 1em;
			margin-left: -1em;
		}

		.privacy-policy .privacy-policy-box .span-gold {
			color: #b19b66;
		}

		@media (min-width: 992px) {
			.padding-lr {
				padding: 0 200px;
			}
		}