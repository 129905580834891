.header {
    position: relative;
    margin-bottom: 350px;

    @media (min-width:360px) {
        margin-bottom: 250px;
    }

    @include md {
        margin-bottom: 40px;
    }

    @include lg {
        margin-bottom: unset;
    }

    .menu {
        display: flex;
        width: 100%;
        position: relative;
        height: 220px;

        @include xl {
            height: auto;
        }

        &.open {
            .header-nav .header-list {
                display: flex;
            }
        }


    }

    .header-logo {
        background-color: #00011f;
        padding: 20px 13px;

        @include md {
            padding: 6px 55px;
        }

        img {
            width: 160px;
            height: auto;

            @include md {
                margin-top: 15px;
                width: 190px;
            }
        }
    }

    .header-nav {
        background-color: #b19b66;
        flex-grow: 1;
        order: 3;


        @include lg {
            order: 0;
        }

        .menu-btn {
            width: 30px;
            height: 22px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            margin-top: 50px;
            margin-left: auto;

            p {
                width: 100%;
                height: 2px;
                background-color: $color-light;
                margin: 0;
            }

            @include lg {
                display: none;
            }
        }

        .header-list {
            list-style-type: none;
            padding-top: 40px;
            padding-bottom: 40px;
            margin: 0;
            flex-direction: column;
            z-index: 99999;
            position: absolute;
            text-align: right;
            right: 20px;
            display: none;
            right: 0;
            position: absolute;
            padding-right: 20px;
            padding-left: 50px;
            background-color: $color-main2;
            top: 120px;
            width: 100vw;
            height: 100vh;

            @include lg {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: row;
                position: static;
                text-align: left;
                padding-bottom: 130px;
                padding-left: 0;
                padding-right: 0;
                padding-top: 20px;
                width: unset;
                height: unset;
            }

            @include xl {
                flex-wrap: nowrap;
                justify-content: space-between;
                padding-top: 40px;
            }

            .header-item {
                border: none;
                transition: $transition;
                margin-bottom: 20px;
                border: 1px solid transparent;

                @include lg {
                    margin-bottom: 0;
                }

                a {
                    font-size: 12px;
                    letter-spacing: 1.3px;
                    text-transform: uppercase;
                    color: $color-light;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 12px 9px;
                    font-weight: 600;
                    font-family: $font-third;
                }

                &:hover {
                    @include lg {
                        border: 1px solid $color-light;
                    }

                }
            }
        }
    }

    .header-link {
        display: none;
        flex-direction: column;
        background-color: #a38c56;
        padding-top: 32px;

        a {
            text-align: center;
        }

        @include md {
            display: flex;
        }
    }

    .header-block1 {
        height: 480px;
        width: 260px;
        background-color: $color-main2;
        display: inline-block;

        @include xl {
            height: 540px;
        }
    }

    .header-block2 {
        height: 200px;
        width: 50%;
        background-color: $color-main;
        display: inline-block;
        position: absolute;
        right: 0;

        .mask {
            width: 100%;
            height: 100%;
            background-image: url(../images/header-mask.jpg);
            background-size: cover;
            background-position: center;
            opacity: 0.2;
        }
    }

    .header-main {
        position: absolute;
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        top: 130px;

        @include md {
            flex-direction: row;
            height: 550px;
            bottom: 30px;
            top: unset;
        }

        @include xl {
            height: 600px;
        }

        .header-main-text {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 50px;
            background-image: url(../images/header-mask.jpg);
            background-size: cover;
            background-position: left;

            @include md {
                width: 45%;
            }

            @include xl {
                width: 100%;
            }

            .mask {
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: $color-main;

                opacity: 0.9;
                left: 0;
                top: 0;
                filter: contrast(1)
            }

            .title {
                color: $color-light;
                margin-bottom: 27px;
                z-index: 9;
                text-align: center;

                @include lg {
                    text-align: left;
                }
            }

            .header-txt {
                color: $color-light;
                margin-bottom: 27px;
                z-index: 9;
                text-align: center;
                font-size: 15px;

                @include lg {
                    text-align: left;
                    font-size: 17px;
                }
            }

            .button {
                width: max-content;
                z-index: 9;
                margin: auto;

                @include lg {
                    margin: unset;
                }
            }
        }

        .header-main-img {
            width: 100%;
            background-image: url(../images/header-img.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 220px;

            @include md {
                width: 55%;
                height: unset;
            }

            @include xl {
                width: 70%;
            }
        }
    }
}

.header-small {
    margin-bottom: 18px;

    .menu {
        height: 130px;

        @include md {
            height: 155px;
        }

        @include xl {
            height: auto;
        }

        .header-nav .header-list {
            padding: 25px 0 31px;
            margin-top: 8px;
        }
    }

    .header-podstrona {
        display: flex;
        flex-wrap: wrap;


        &.p-right {
            padding-right: 0px;

            @include lg {
                padding-right: 30px;
            }
        }
    }

    .header-podstrona-img {
        width: 100%;
        //        height: 400px;
        height: 200px;
        background-image: url(../images/header-img.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include lg {
            width: calc(50% - 30px);
            height: auto;
        }
    }

//    .header-aboutme {
//        background-image: url(../images/dominika-miskiewicz-prawnik.jpg);
//    }
    
    .header-aboutme {
//        background-image: url(../images/test.png);
        background-image: url(../images/adwokat-.JPG);
        
    }
    
    @media (min-width:992px) {
    .header-aboutme {
    background-size: 54%;
    background-position: 50% 50%;
    }
    }

   

    @media (max-width:992px) {
        .header-aboutme {
            background-position-y: -156px;
        }
    }
    
     @media (max-width:575px) {
        .header-aboutme {
            background-position-y: -64px;
        }
    }


    .header-lodz-img {
        width: 100%;
        height: 200px;
        background-image: url(../images/adwokat-lodz-1.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;



        @include md {
            height: 400px;
        }

        @include lg {
            width: calc(6%) !important;
            height: auto;
        }
    }

    @media (min-width: 992px) {
        .header-lodz-right {
            width: calc(91% + 30px) !important;
        }
    }



    .header-medic {
        width: 100%;
        height: 200px;
        //        background-image: url(../images/video/prawo-medyczne-lodz.webm);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include lg {
            width: calc(50% - 30px);
            height: auto;
        }

        img {
            width: calc(100%);
            height: 100%;
            object-fit: cover;
        }
    }



    .header-kontakt {
        width: 100%;
        height: 400px;

        @include lg {
            width: calc(50% - 30px);
            height: auto;
        }
    }

    .header-podstrona-text {
        width: 100%;
        padding-top: 65px;
        padding-bottom: 0;
        text-align: left;
        padding-right: 30px;

        @include md {
            text-align: center;
        }

        @include lg {
            padding-top: 110px;
            width: calc(50% + 30px);
            text-align: unset;
            padding-bottom: 80px;
            padding-right: 130px;
        }

        .header-line {
            @include line;
            z-index: -1;
            margin-top: 0;
            height: 30px;

            .arrow-bg {
                height: 30px;
                left: calc(50% - 25px);

                @include lg {
                    left: 50px;
                }
            }
        }

        p.italic {
            font-size: 16px;
            line-height: 1.5;
            font-style: italic;
            margin-bottom: 25px;
        }

        .title3 {
            margin-bottom: 30px;

            &:last-of-type {
                margin-top: 30px;
            }

            &.square {
                position: relative;

                &::before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 0 7px 10px;
                    border-color: transparent transparent transparent $color-main2;
                    position: absolute;
                    left: -41px;
                    z-index: -1;
                }
            }
        }

        .title-kontakt {
            margin-bottom: 15px;
            margin-top: 20px;
        }

    }

    .medic-side {
        padding-top: 30px;
        padding-bottom: 50px;

        @include md {
            padding-top: 60px;
        }
    }
}

.medic-page-info {
    margin-top: -50px;

    .title3 {
        font-size: 15px;
        margin-top: 30px;

    }
}
