// Base styles
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  @include reset;
  font-family: $font-main;
  font-weight: 400;
  line-height: 1.4;
  font-size: 16px;
  color: $color-text;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

p {
  font-family: $font-main;
  line-height: 1.5;
}

//headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-main;
}

.title {
  font-family: $font-main;
  font-size: 23px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 37px;
  margin-bottom: 10px;
  color: $color-main;

  @include lg {
    font-size: 30px;
    line-height: 45px;
  }

  .color {
    color: $color-main2;
  }
}

.title2 {
  font-size: 18px;
  color: $color-main;
  font-family: $font-main;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.7px;
}

.title3 {
  font-size: 12px;
  color: $color-main2;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

ul{
    margin: 0;
    list-style: none;
}

//links
a,
a:active,
a:focus,
a:visited {
  color: $color-main;
  text-decoration: none;

  &:hover {
    color: $color-main;
    text-decoration: none;
  }
}

//buttons
.button,
button.button,
a.button {
  color: $color-main2;
  font-size: 11px;
  font-family: $font-main;
  text-transform: uppercase;
  font-weight: 700;
  border: 1px solid $color-main2;
  padding: 18px 20px;
  font-family: $font-third;
  letter-spacing: 1.3px;
  transition: $transition;

  &:hover {
    color: $color-light;
    background-color: $color-main2;
    border: 1px solid $color-main2;
  }

  &.button-light {
    color: $color-light;
    font-weight: 400;
    border: 1px solid $color-light;
  }

  &.button-light:hover {
    background-color: $color-light;
    color: $color-main2;
  }
}

//common
.cta {
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 1.2;
  color: $color-main;
  text-transform: uppercase;
  font-family: $font-main;
}

.mt-2{
    margin-top: 20px;
    display: inline-block;
}

.mt-3{
    margin-top: 30px;
    display: inline-block;
}

.mb-5{
    margin-bottom: 50px;
}

.mb-8{
    margin-bottom: 80px !important;
}

.img-fluid{
    width: 100%;
    height: auto;
}

/* GDPR */
#gdpr-cookie-message a,
#gdpr-cookie-message a:hover,
#gdpr-cookie-message button:hover {
    transition: all .3s ease-in
}

#gdpr-cookie-message button:hover,
button#gdpr-cookie-advanced {
    background: #fff;
    color: var(--orange)
}

:root {
    --grey: #a38c56;
    --orange: #020333
}

input[type=checkbox] {
    margin-top: 10px;
    margin-right: 15px;
    float: left
}

label {
    display: inline-block;
    max-width: 90%
}

code {
    color: var(--orange);
    font-family: monospace;
    font-size: 16px;
    background: #f7f7f7;
    padding: 3px 5px;
    border-radius: 3px;
    border: 1px solid #ebebeb
}

button#ihavecookiesBtn {
    margin-left: 0 !important
}

#gdpr-cookie-message {
    position: fixed;
    right: 30px;
    bottom: 30px;
    max-width: 375px;
    background-color: var(--grey);
    padding: 14px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, .25);
    margin-left: 30px;
    font-family: system-ui;
    z-index: 111111111;
}

#gdpr-cookie-message button,
#gdpr-cookie-message h4,
#gdpr-cookie-message h5,
button#ihavecookiesBtn {
    font-family: Quicksand, sans-serif
}

#gdpr-cookie-message h4 {
    color: var(--orange);
    font-size: 16px;
    margin-top: 5px;
    font-weight: 500;
    margin-bottom: 2px
}

#gdpr-cookie-message h5 {
    color: var(--orange);
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px
}

#gdpr-cookie-message p,
#gdpr-cookie-message ul {
    color: #fff;
    font-size: 12px;
    line-height: 1.5em
}

#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: right
}

#gdpr-cookie-message li {
    width: 49%;
    display: inline-block
}

#gdpr-cookie-message a {
    color: var(--orange);
    text-decoration: none;
    font-size: 12px;
    padding-bottom: 2px;
    border-bottom: 1px dotted rgba(255, 255, 255, .75)
}

#gdpr-cookie-message a:hover {
    color: #fff;
    border-bottom-color: var(--orange)
}

#gdpr-cookie-message button,
button#ihavecookiesBtn {
    border: none;
    background: var(--orange);
    color: #fff;
    font-size: 15px;
    padding: 7px;
    border-radius: 3px;
    margin-left: 15px;
    cursor: pointer;
    transition: all .3s ease-in
}

#gdpr-cookie-message button:disabled {
    opacity: .3
}

#gdpr-cookie-message input[type=checkbox] {
    float: none;
    margin-top: 0;
    margin-right: 5px
}

@media (max-width: 576px) {
    code {
    font-size: 12px;
}
    #gdpr-cookie-message h4 {
    font-size: 14px;
}
#gdpr-cookie-message {
    position: fixed;
    right: 10px;
    bottom: 10px;
    max-width: 375px;
    padding: 10px;
}
    #gdpr-cookie-message a {
    font-size: 11px;
    padding-bottom: 2px;
}
#gdpr-cookie-message button,
button#ihavecookiesBtn {
    font-size: 11px;
}
    #gdpr-cookie-message p,
#gdpr-cookie-message ul {
    font-size: 10px;
}
}

.p-font{
    font-size: 15px !important;
}

 .specialization .specialization-list a:before {
            content: " ";
        }
.mb-5{
    margin-bottom: 50px !important;
}

.mb-3{
    margin-bottom: 30px !important;
}

.fs-18{
    font-size: 18px;
}

.blue{
    color: #162768;
    font-size: 14px;
    margin-top: 32px;
}

.mr-15{
    margin-right: 15px;
}

.ml-15{
    margin-left: 15px;
}

.pr-pt-0{
    padding-right: 0 !important;
    padding-top: 0 !important;
}

ul{
    margin-left: 12px;
        margin-top: -5px;
}