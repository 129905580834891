.about {
    .about-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding-top: 40px;
            padding-bottom: 40px;

            .about-item {
                width: 100%;

                @include lg {
                    width: 47%;
                }
            }
        

        .button {
            margin: auto;
            margin-top: 20px;
        }
    }
}

@media (min-width:992px){
    #blog-slider{
    .carousel-item{
        display: flex;
    }
}
}
