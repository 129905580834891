.section-border {
    margin: 50px 0 50px;

    .title2 {
        position: relative;
        padding-top: 20px;

        &::before {
            content: "";
            position: absolute;
            background-color: $color-light;
            clip-path: polygon(0 0, 70% 1%, 100% 100%, 30% 100%);
            height: calc(100% + 80px);
            width: 295px;
            top: -7px;
            left: -65px;
            z-index: -1;
        }
    }

    .title-p {
        font-style: italic;
        font-family: $font-main-light;
    }

    .section-border-items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 30px;
        flex-wrap: wrap;
        position: relative;

        @include lg {
            flex-direction: row;
            justify-content: space-between;
        }

        .section-border-item {
            width: 100%;

            @include lg {
                width: calc(50% - 30px);
            }

            span {
                font-weight: bold;
                color: $color-main2;
            }
        }
    }

    .section-border-main {
        position: relative;
        padding: 40px;
    }

    .section-border-frame {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 35px;
        border: 17px solid #f1f1f1;
        box-sizing: border-box;
        z-index: -3;
    }

    .section-border-frame2 {
        position: absolute;
        left: -11px;
        border-left: 17px solid $color-main2;
        border-bottom: 17px solid $color-main2;
        box-sizing: border-box;
        z-index: -2;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        width: 60%;
        height: 30%;
        bottom: -24px;

        @include md {
            width: 33%;
            height: 68%;
            bottom: -24px;
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }

        @include lg {
            width: 150px;
            height: 150px;
        }
    }

    .section-border-frame3 {
        position: absolute;
        right: 0;
        width: 40%;
        height: 17px;
        top: 25px;
        background-color: $color-main2;
        box-sizing: border-box;
        z-index: -2;
        clip-path: polygon(0 0, 100% 0, 88% 100%, 12% 99%);

        @include md {
            clip-path: polygon(0 0, 100% 0, 97% 100%, 3% 99%);
            width: 70%;
            right: unset;
            left: 200px;
        }
    }




}