.map {
    height: 600px;
    width: 100%;
    background-image: url(../images/map.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 130px;

    .title-block {
        width: 100%;
        transform: translateY(-50%);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: $color-main2;
        color: $color-light;
        font-size: 11px;
        letter-spacing: 2px;
        padding: 18px 17px;
        text-transform: uppercase;
        font-family: $font-third;
        font-weight: 700;

        @include md {
            margin-left: 80px;
            margin-right: 80px;
            width: unset;
        }
    }
}